import { ChecklistDataContainer } from "@dashboard/process/views/checklists/ChecklistView"
import { get, getUnuthenticated, post, put } from "../setup"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"

export type ShareChecklistBody = { metaData: ChecklistDataContainer } & {
  emailAddresses: Array<string>
  organization: { name: string; logo?: string }
}

export type GetChecklistResponse = { metaData: ChecklistDataContainer } & {
  emailAddress: string
  createdAt: string
  id: string
  organization: {
    name: string
    logo?: string
  }
}

export const shareChecklistApi = (body: ShareChecklistBody) =>
  post<ShareChecklistBody, Array<string>>("/checklist/public-share", body)

export const getPublicCheckListApi = (uuid: string) =>
  getUnuthenticated<GetChecklistResponse>(`/checklist/public-share/${uuid}`)

export function useGetPublicChecklist(uuid: string) {
  return useQuery({
    queryKey: [`public-checklist/${uuid}`],
    queryFn: async () => {
      const response = await getPublicCheckListApi(uuid)
      return response
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  })
}

export function useShareChecklistToPublic() {
  const client = useQueryClient()
  return useMutation(shareChecklistApi, {
    onError: (e: Error) => {
      console.error(e)
    },
  })
}

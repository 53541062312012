import { Button, Col, Nav, NavLink, Row, TabContent, TabPane, Progress } from "reactstrap"
import EcosuiteForm from "@common/form/EcosuiteForm"
import React, { ReactElement, useState } from "react"
import {
  ChecklistDataContainer,
  ChecklistSchemaContainer,
  ChecklistTab,
  indexToTab,
} from "@dashboard/process/views/checklists/ChecklistView"
import i18n from "src/i18n"
import { overwriteProjectChecklist } from "./ChecklistService"
import { Loading } from "@common/EcosuiteComponent"
import _, { cloneDeep } from "lodash"
import BackdropDialog, { BackdropDialogFunction } from "@common/input/button/BackdropDialog"
import { importToChecklist } from "@dashboard/process/views/checklists/ImportService"
import Icon from "@common/display/Icon"
import { generateUiSchema } from "@dashboard/process/views/checklists/ChecklistUiSchema"
import { produce } from "immer"
import { generateConfetti } from "@dashboard/data/project/confetti"
import ShareChecklist from "src/PublicView/Checklist/ShareChecklist"

function alphabeticallyOrderUiSchema(schema: any) {
  if (!schema.properties) {
    return schema
  }
  const r = produce(schema, (draft: any) => {
    Object.keys(draft.properties).forEach((item) => {
      draft.properties[item]["ui:order"] = [...Object.keys(draft.properties[item].properties).sort()]
    })
  })
  return cloneDeep(r)
}

const { t } = i18n

/**
 * The checklist form props.
 */
interface ChecklistFormProps {
  code: string
  state: string
  tab: ChecklistTab
  setTab: (tab: ChecklistTab) => void
  checklistSchemas: ChecklistSchemaContainer
  checklistData: ChecklistDataContainer
  editedChecklistData: ChecklistDataContainer
  setEditedChecklistData: (container: ChecklistDataContainer) => void
  refreshChecklistSchemas: () => void
  refreshChecklistData: () => void
  checklistTabs: Array<"NTP" | "PTO" | "ICR">
}

/**
 * The checklist form.
 * @param props - The props.
 * @constructor
 */
export const ChecklistForm = (props: ChecklistFormProps) => {
  const { checklistTabs } = props

  const [isActioning, setIsActioning] = useState<boolean>(false)
  const [isImportDialogOpen, setIsImportDialogOpen] = useState<boolean>(false)
  const [confetti, setConfetti] = useState(false)

  const activateCelebration = () => {
    setConfetti(true)
    setTimeout(() => {
      setConfetti(false)
    }, 3000)
  }

  /**
   * Save all checklists.
   */
  async function saveAllChecklists(): Promise<void> {
    // Add all changes to the todo list.
    const todo: Promise<unknown>[] = []

    const ntpEdit = props.editedChecklistData.ntpData
    const ptoEdit = props.editedChecklistData.ptoData
    const icrEdit = props.editedChecklistData.icrData
    if (!_.isEqual(ntpEdit, props.checklistData.ntpData)) {
      todo.push(overwriteProjectChecklist(props.code, ChecklistTab.NTP, ntpEdit))
    }
    if (!_.isEqual(ptoEdit, props.checklistData.ptoData)) {
      todo.push(overwriteProjectChecklist(props.code, ChecklistTab.PTO, ptoEdit))
    }
    if (!_.isEqual(icrEdit, props.checklistData.icrData)) {
      todo.push(overwriteProjectChecklist(props.code, ChecklistTab.ICR, icrEdit))
    }

    if (todo.length > 0) {
      setIsActioning(true)
      await Promise.all(todo)
      await props.refreshChecklistData()
      setIsActioning(false)
    }

    return
  }

  /**
   * Get the tab schema
   * @param tab - The tab.
   */
  function getTabSchema(tab: ChecklistTab): Schema | undefined {
    switch (tab) {
      case ChecklistTab.NTP:
        return alphabeticallyOrderUiSchema(props.checklistSchemas.ntpSchema)
      case ChecklistTab.PTO:
        return alphabeticallyOrderUiSchema(props.checklistSchemas.ptoSchema)
      case ChecklistTab.ICR:
        return alphabeticallyOrderUiSchema(props.checklistSchemas.icrSchema)
    }
  }

  /**
   * Get the tab data.
   * @param tab - The tab.
   */
  function getTabData(tab: ChecklistTab): unknown | undefined {
    switch (tab) {
      case ChecklistTab.NTP:
        return props.editedChecklistData.ntpData
      case ChecklistTab.PTO:
        return props.editedChecklistData.ptoData
      case ChecklistTab.ICR:
        return props.editedChecklistData.icrData
    }
  }

  function checkListProgress(schema: any, formData: any) {
    let tickedBoxes = 0
    let totalProperties = 0
    if (!formData) {
      return 0
    }
    Object.keys(schema.properties).forEach((key) => {
      if (formData[key] !== undefined) {
        Object.keys(schema.properties[key].properties).forEach((subKey) => {
          if (formData[key][subKey] !== undefined) {
            if (formData[key][subKey].isChecked) {
              ++tickedBoxes
            }
            ++totalProperties
          }
        })
      }
    })
    if (tickedBoxes > 0) {
      return Math.round((tickedBoxes / totalProperties) * 100)
    }
    return 0
  }

  /**
   * Build the body.
   * @param tab - The tab.
   */
  function buildChecklistBody(tab: ChecklistTab): ReactElement {
    const schema = getTabSchema(tab)
    const editing = getTabData(tab)

    let body
    if (isActioning) {
      return <Loading />
    } else if (!schema || !schema.properties) {
      body = <EmptyChecklistPrompt toggle={() => setIsImportDialogOpen(!isImportDialogOpen)} />
    } else {
      delete schema["title"]
      body = (
        <div className="ecogy-form" style={{ overflow: "scroll", height: "100%" }}>
          <EcosuiteForm
            schema={schema}
            formData={editing}
            uiSchema={generateUiSchema(schema, props.tab)}
            onChange={(e) => {
              if (checkListProgress(schema, e.formData) === 100) {
                activateCelebration()
              }
              switch (tab) {
                case ChecklistTab.NTP:
                  props.setEditedChecklistData({
                    ...props.editedChecklistData,
                    ntpData: e.formData,
                  })
                  break
                case ChecklistTab.PTO:
                  props.setEditedChecklistData({
                    ...props.editedChecklistData,
                    ptoData: e.formData,
                  })
                  break
                case ChecklistTab.ICR:
                  props.setEditedChecklistData({
                    ...props.editedChecklistData,
                    icrData: e.formData,
                  })
                  break
              }
            }}
            onSubmit={saveAllChecklists}
          >
            <Row className="ecogy-form-buttons">
              <Col className="button-section" sm="2">
                <Button size="sm" color={"primary"} type="submit" onSubmit={saveAllChecklists}>
                  {t("buttons.save")}
                </Button>
                <Button size="sm" color={"primary"} type="submit" onClick={() => setIsImportDialogOpen(true)}>
                  {t("buttons.import")}
                </Button>
                <ShareChecklist
                  metaData={props.editedChecklistData}
                  schema={{
                    ntpSchema: alphabeticallyOrderUiSchema(props.checklistSchemas.ntpSchema),
                    icrSchema: alphabeticallyOrderUiSchema(props.checklistSchemas.icrSchema),
                    ptoSchema: alphabeticallyOrderUiSchema(props.checklistSchemas.ptoSchema),
                  }}
                  checklistTabs={checklistTabs}
                />
              </Col>
            </Row>
          </EcosuiteForm>
        </div>
      )
    }

    return (
      <>
        <ImportToChecklistDialog
          code={props.code}
          state={props.state}
          isOpen={isImportDialogOpen}
          toggle={() => setIsImportDialogOpen(!isImportDialogOpen)}
          onImport={() => {
            // After importing, refresh the schemas.
            setIsActioning(true)
            props.refreshChecklistSchemas()
            setIsActioning(false)
          }}
        />
        {body}
      </>
    )
  }

  return (
    <>
      <Nav className="nav">
        {checklistTabs.map((_, index) => {
          const tab = indexToTab(index)
          return (
            <NavLink
              key={tab}
              className={`checklist__nav ${props.tab === tab ? "active" : ""}`}
              onClick={() => {
                props.setTab(tab)
              }}
            >
              {tab}
            </NavLink>
          )
        })}
      </Nav>
      <TabContent activeTab={props.tab}>
        {Object.keys(ChecklistTab).map((_, index) => {
          const tab = indexToTab(index)
          const checkListPercentage = checkListProgress(getTabSchema(tab), getTabData(tab))
          let progressLabel = "Checklist Progress"
          if (checkListPercentage > 20) {
            progressLabel = checkListPercentage + "%"
          }
          return (
            <TabPane key={index} tabId={tab} style={{ overflowY: "visible" }}>
              <div style={{ position: "relative", paddingTop: "5px", paddingBottom: "5px" }}>
                {confetti ? generateConfetti() : null}
                <Progress multi>
                  <Progress bar value={checkListPercentage}>
                    {checkListPercentage + "%"}
                  </Progress>
                  {checkListPercentage < 20 ? (
                    <Progress bar value={100} color={"secondary"}>
                      Checklist Progess
                    </Progress>
                  ) : null}
                </Progress>
              </div>
              {buildChecklistBody(tab)}
            </TabPane>
          )
        })}
      </TabContent>
    </>
  )
}

/**
 * The empty checklist prompt props.
 */
interface EmptyChecklistPromptProps {
  toggle: () => void
}

/**
 * The empty checklist prompt.
 * @param props - The props.
 * @constructor
 */
const EmptyChecklistPrompt = (props: EmptyChecklistPromptProps) => {
  return (
    <div className="Info">
      <h2 className="info-title">
        <Icon icon="info_outline" />
      </h2>
      <p className="info-message">{t("process.messages.empty_checklist_prompt")}</p>
      <Button color={"primary"} type="submit" onClick={props.toggle}>
        {t("buttons.import")}
      </Button>
    </div>
  )
}

/**
 * The import to checklist dialog props.
 */
interface ImportToChecklistDialogProps {
  code: string
  state: string
  isOpen: boolean
  toggle: () => void
  onImport?: () => void
}

/**
 * The import to checklist dialog.
 * @param props - The props.
 * @constructor
 */
const ImportToChecklistDialog = (props: ImportToChecklistDialogProps) => {
  /**
   * Import to checklist.
   */
  async function importToChecklistAction(): Promise<void> {
    await importToChecklist(props.code)
    return
  }

  return (
    <BackdropDialog
      type={BackdropDialogFunction.Simple}
      title={t("process.headers.import_to_checklist")}
      simpleProps={{
        performText: t("process.messages.importing") ?? "",
      }}
      bodyText={t("process.messages.import_to_checklist") ?? ""}
      isOpen={props.isOpen}
      onCancel={props.toggle}
      onConfirm={async () => {
        // Import the data.
        await importToChecklistAction()
      }}
      onComplete={() => {
        props.onImport?.()
        // Close the dialog.
        props.toggle()
      }}
      inputProps={{
        performText: t("process.messages.importing") ?? "",
      }}
      displayText={`${t("process.messages.about_to_import")} ${props.state}`}
    />
  )
}

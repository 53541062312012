import React from "react"
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import { InviteUserPayload, inviteUserToOrganizationApi } from "src/services/users"
import { Button, CheckboxField, Flex, Text, ThemeProvider, SelectField } from "@aws-amplify/ui-react"
import { TextField } from "../../SuperAdmin/AmplifyTextFields"
import "../../SuperAdmin/dark.scss"
import { theme } from "src"
import UserAdminService from "./UserAdminService"
import { toast } from "react-toastify"

export const selectFieldStyle = {
  borderColor: "white",
  color: "white",
  backgroundColor: "transparent",
  "& label": { color: "white" },
  "& .amplify-select__control": {
    borderColor: "white",
    color: "white",
    backgroundColor: "transparent",
  },
  "& .amplify-select__single-value": { color: "white" },
  "& .amplify-select__menu": { backgroundColor: "black" },
  "& .amplify-select__option": { color: "white" },
}

export const availableGroups = [
  "connectivity",
  "consumption",
  "contacts",
  "data-analysis",
  "data-write",
  "demandresponse",
  "energy",
  "event",
  "event-write",
  "finance",
  "generation",
  "legal",
  "notes",
  "power-user",
  "processes",
  "reseller",
  "solarnetwork",
  "storage",
  "user-admin",
  "user-limited-admin",
]

interface FormData extends Omit<InviteUserPayload, "accessPolicies"> {
  accessPolicies: Record<string, boolean>
}

interface UserType {
  id: string
  name: string
  defaultGroups: string[]
}

const InviteUserToOrganization: React.FC = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>()

  const [userTypes, setUserTypes] = React.useState<UserType[]>([])

  const queryClient = useQueryClient()

  React.useEffect(() => {
    // eslint-disable-next-line
    ;(async () => {
      const fetchedUserTypes = (await UserAdminService.getUserTypes()) as { userTypes: UserType[] }
      setUserTypes(fetchedUserTypes.userTypes)
    })()
  }, [])

  const mutation = useMutation(inviteUserToOrganizationApi, {
    onSuccess: () => {
      queryClient.refetchQueries(["externalUsers"], { active: false })
      reset()
      toast.success("User invited successfully!")
    },
    onError: (error: Error) => {
      toast.error(`An error occured: ${error.message}`)
    },
  })

  const onSubmit: SubmitHandler<FormData> = (data) => {
    const selectedPolicies = Object.entries(data.accessPolicies)
      .filter(([_, isChecked]) => isChecked)
      .map(([policy]) => policy)

    const payload: InviteUserPayload = {
      ...data,
      accessPolicies: selectedPolicies,
    }

    mutation.mutate(payload)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: "0 8px" }}>
      <ThemeProvider theme={theme}>
        <Controller
          name="userEmail"
          control={control}
          rules={{ required: "Email is required" }}
          render={({ field }) => (
            <TextField
              placeholder="john@example.com"
              label="Email"
              type="email"
              errorMessage={errors.userEmail?.message}
              hasError={!!errors.userEmail}
              {...field}
            />
          )}
        />

        <Text color="white" fontSize={16} fontWeight={600} marginBottom={16} marginTop={16}>
          Access Policies
        </Text>
        <Flex direction="column" gap="0.5rem">
          {availableGroups.map((group) => (
            <Controller
              key={group}
              name={`accessPolicies.${group}`}
              control={control}
              defaultValue={false}
              render={({ field: { onChange, value, ...field } }) => (
                <CheckboxField
                  label={group}
                  value={group}
                  checked={value}
                  onChange={(e) => onChange(e.target.checked)}
                  {...field}
                />
              )}
            />
          ))}
        </Flex>

        <div style={{ marginTop: "16px" }}>
          <Controller
            name="userTypeId"
            control={control}
            rules={{ required: "User Type is required" }}
            render={({ field }) => (
              <SelectField
                label="User Type"
                errorMessage={errors.userTypeId?.message}
                hasError={!!errors.userTypeId}
                style={selectFieldStyle}
                className={"white-border-select"}
                {...field}
              >
                <option value="">Select a user type</option>
                {userTypes.map((userType) => (
                  <option key={userType.id} value={userType.id}>
                    {userType.name}
                  </option>
                ))}
              </SelectField>
            )}
          />
        </div>

        <Button type="submit" isLoading={mutation.isLoading} style={{ marginTop: "16px" }} variation="primary">
          {mutation.isLoading ? "Inviting..." : "Invite User"}
        </Button>
      </ThemeProvider>
    </form>
  )
}

export default InviteUserToOrganization

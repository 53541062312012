import * as React from "react";
import { Button, SelectField } from "@aws-amplify/ui-react";
import { SubmitHandler, useForm, useFieldArray, FormProvider } from "react-hook-form";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Table } from "ka-table";
import { toast } from "react-toastify";

import {
  Organization,
  useOrganization,
  useUpdateOrganization,
  Integration,
  ServiceIdentifier
} from "src/services/organization";
import { formatData } from "src/SuperAdmin/helpers";
import OrganizationFormFields, { OrganizationDataSharingFeilds, OrganizationAdvancedFeilds } from "src/SuperAdmin/OrganizationFormFields";
import { CredentialService } from "./CredentialService";
import IntegrationsService, { IntegrationMapping, IntegrationMappingProperty } from "./IntegrationsService";
//import { IntegrationFields } from "./IntegrationProperties";
import { SolarNetworkObjectList } from "./SolarNetworkObject";

interface Props {
  organizationId: string;
  projects: Project[];
  profiles?: Array<{ id: string; name: string }>; // New prop for available profiles
}

interface Props {
  organizationId: string,
  projects: Project[]
}

interface ChildTypes {
  mappings: IntegrationMapping;
}

export default function OrganizationSettingsForm(props: Props) {
  const { organizationId, projects } = props
  const { data: thisOrg, isLoading, isError } = useOrganization(organizationId)
  const { mutateAsync, isLoading: isUpdating } = useUpdateOrganization()
  const [submitting, setSubmitting] = React.useState(false)
  const [tabs, setTab] = React.useState("general")
  const [selectedProfile, setSelectedProfile] = React.useState<string>("");

  // Just get the credential names directly
  const profiles = React.useMemo(() => 
    CredentialService.getConfiguredCredentialNames(thisOrg),
    [thisOrg]
  );

  const form = useForm<Organization>({
    defaultValues: {},
  })
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    watch,
    reset,
    setValue,
    register,
  } = form
  const { fields, append, remove } = useFieldArray({
    control,
    name: "integrations",
  });

  // Add this state to store initial integration values
  const [initialIntegrations, setInitialIntegrations] = React.useState<Integration[]>([]);

  // Modify the loadIntegrations effect to store initial state
  React.useEffect(() => {
    async function loadIntegrations(alias: string) {
      try {
        const integrations = await IntegrationsService.listIntegrations(alias);
        const formattedIntegrations = integrations.map(integration => ({
          name: integration.name,
          serviceIdentifier: integration.serviceIdentifier,
          enabled: integration.enabled,
          properties: integration.serviceProperties || {},
          configId: integration.configId,
        }));
        
        // Store the initial state
        setInitialIntegrations(formattedIntegrations);
        
        // Update the form
        setValue('integrations', formattedIntegrations, {
          shouldDirty: false,
          shouldTouch: false
        });
      } catch (error) {
        console.error('Failed to load integrations:', error);
        toast.error('Failed to load integrations');
      }
    }

    if (selectedProfile) {
      loadIntegrations(selectedProfile);
    }
  }, [selectedProfile, setValue]);

  const watchEnableSharing = watch("dataSharing.enableSharing", "Do not share my data")

  React.useEffect(() => {
    if (organizationId === thisOrg?.id) {
      reset(formatData(thisOrg))
    }
  }, [thisOrg, organizationId])

  // Modify the submit handler to compare against initialIntegrations
  const onSubmit: SubmitHandler<Organization> = async (data) => {
    setSubmitting(true);
    try {
      const dirtyIntegrations = (data.integrations ?? []).filter((integration, index) => {
        const originalIntegration = initialIntegrations.find(i => i.configId === integration.configId);
        if (!originalIntegration) return true;
        
        return JSON.stringify({
          name: integration.name,
          enabled: integration.enabled,
          serviceProperties: integration.properties
        }) !== JSON.stringify({
          name: originalIntegration.name,
          enabled: originalIntegration.enabled,
          serviceProperties: originalIntegration.properties
        });
      });
      
      // Modified this section to handle the responses
      const updateResults = await Promise.all(
        dirtyIntegrations.map(async (integration) => {
          const isNewIntegration = !integration.configId;

          const apiIntegration = {
            ...integration,
            serviceProperties: integration.properties,
          };
          (apiIntegration as any).properties = undefined;

          if (isNewIntegration) {
            return IntegrationsService.createIntegration(selectedProfile, apiIntegration);
          } else {
            return IntegrationsService.updateIntegration(selectedProfile, apiIntegration.configId?.toString() ?? "", apiIntegration);
          }
        })
      );

      if (updateResults?.length) {
        toast.success("Integrations updated successfully!");
        
        // After successful update, refresh the initial state
        const apiResponse = await IntegrationsService.listIntegrations(selectedProfile);
        const formattedIntegrations = apiResponse.map(integration => ({
          name: integration.name,
          serviceIdentifier: integration.serviceIdentifier as ServiceIdentifier,
          enabled: integration.enabled,
          properties: integration.serviceProperties || {},
          configId: integration.configId,
        }));

        setInitialIntegrations(formattedIntegrations);
        setValue('integrations', formattedIntegrations, { shouldDirty: false });
      }
    } catch (error) {
      console.error('Failed to update integrations:', error);
      toast.error("Failed to update integrations!");
    } finally {
      setSubmitting(false);
    }
  };

  if (isLoading) return <div>Loading...</div>
  if (isError) return <div>Error while fetching organization</div>

  function renderDataSharingTable() {
    if (!projects) { return }
    return (
      <div className="table custom-theme-demo">
        <Table
          data={projects}
          rowKeyField={"Project Code"}
          columns={[
            {
              key: "name",
              title: "Project Name",
              width: 250,
            },
            {
              key: "code",
              title: "Project Code",
              width: 180,
            },
            {
              key: "Partners",
              title: "Partners",
              width: 180,
            },
            {
              key: "Earnings",
              title: "Earnings",
              width: 180,
            }
          ]}
        />
      </div>
    )
  }

  const renderIntegrationsTab = () => (
    <TabPane tabId={"Energy API's"}>
      <h3 style={{ marginTop: "8px" }}>Integrations</h3>
      <p>Configure third-party service integrations for your organization</p>
      
      {/* Profile selector */}
      <div style={{ marginBottom: '1rem' }}>
        <label style={{ marginBottom: '0.5rem', display: 'block' }}>
          Select Profile
        </label>
        <select 
          value={selectedProfile}
          onChange={(e) => setSelectedProfile(e.target.value)}
          style={{ width: '100%', maxWidth: '500px' }}
        >
          <option value="">Select a profile</option>
          {profiles.map(profile => (
            <option key={profile} value={profile}>
              {profile}
            </option>
          ))}
        </select>
      </div>

      {selectedProfile && (
        <SolarNetworkObjectList<Integration, ChildTypes>
          title="Integration"
          objects={fields}
          fields={(parents) => [
            { name: 'name', label: 'Name', type: 'text' },
            { 
              name: 'serviceIdentifier', 
              label: 'Service', 
              type: 'select',
              options: [
                { label: 'Locus Energy', value: 's10k.c2c.i9n.locus' },
                { label: 'Solrenview', value: 's10k.c2c.i9n.solrenview' },
                { label: 'SolarEdge V1', value: 's10k.c2c.i9n.solaredge.v1' },
                { label: 'eGauge', value: 's10k.c2c.i9n.egauge' }
              ]
            },
            { name: 'enabled', label: 'Enabled', type: 'checkbox' }
          ]}
          onAdd={async (newObject) => {
            const integration = {
              ...newObject,
              properties: {},
              enabled: true
            };
            await IntegrationsService.createIntegration(selectedProfile, {
              ...integration,
              name: integration.name || '',
              serviceIdentifier: integration.serviceIdentifier as ServiceIdentifier
            });
          }}
          onUpdate={async (object) => {
            if (!object.configId) return;
            const integration = {
              ...object,
              serviceProperties: object.properties
            };
            await IntegrationsService.updateIntegration(selectedProfile, object.configId.toString(), integration);
          }}
          onDelete={async (object) => {
            if (!object.configId) return;
            await IntegrationsService.deleteIntegration(selectedProfile, object.configId.toString());
          }}
          isLoading={isLoading}
          childConfigs={{
            mappings: {
              title: "Mappings",
              parentIdField: "configId",
              fields: (parents) => [
                { name: 'name', label: 'Name', type: 'text' },
              ],
              onAdd: async (parentId, newMapping) => {
                await IntegrationsService.createMapping(selectedProfile, parentId.toString(), {
                  ...newMapping,
                  integrationId: parentId,
                } as IntegrationMapping);
              },
              onUpdate: async (parentId, mapping) => {
                //console.log("onUpdate", parentId, mapping);
              },
              onDelete: async (parentId, mapping) => {
                await IntegrationsService.deleteMapping(selectedProfile, parentId.toString(), mapping.configId.toString());
              },
              getChildren: async (parentId) => {
                return await IntegrationsService.listMappings(selectedProfile, parentId.toString());
              },
              childConfigs: {
                properties: {
                  title: "Properties",
                  hideEdit: true,
                  parentIdField: "configId",
                  fields: (parents) => [
                    { name: 'propertyName', label: 'Property Name', type: 'text' },
                    { name: 'propertyType', label: 'Property Type', type: 'select', 
                      options: [
                        { label: 'Instantaneous', value: 'i' },
                        { label: 'Accumulating', value: 'a' },
                        { label: 'Status', value: 's' }
                      ]
                    },
                    { name: 'valueType', label: 'Value Type', type: 'select',
                      options: [
                        { label: 'Reference', value: 'r' },
                        { label: 'Spel Expression', value: 's' }
                      ]
                    },
                    { name: 'valueReference', label: 'Value Reference', type: 'text' },
                    { name: 'multiplier', label: 'Multiplier', type: 'number' },
                    { name: 'scale', label: 'Decimal Places', type: 'number' },
                    { name: 'enabled', label: 'Enabled', type: 'checkbox' }
                  ],
                  onAdd: async (parentId: any, newProperty: any) => {
                    await IntegrationsService.createMappingProperty(selectedProfile, parentId.toString(), {
                      ...newProperty,
                      mappingId: parentId,
                    } as IntegrationMappingProperty);
                  },
                  onUpdate: async (parentId: any, property: any, index: number) => {

                    delete property.modified;
                    delete property.created;
                    delete property.datumStreamMappingId;
                    delete property.userId;

                    await IntegrationsService.updateMappingProperty(
                      selectedProfile,
                      "0",
                      parentId.toString(),
                      index,
                      property
                    );
                  },
                  onDelete: async (parentId: any, property: any, index: number) => {
                    await IntegrationsService.deleteMappingProperty(
                      selectedProfile,
                      "0",
                      parentId.toString(),
                      index
                    );
                  },
                  getChildren: async (parentId: any) => {
                    return IntegrationsService.listMappingProperties(selectedProfile, parentId.toString());
                  }
                },
                dataStreams: {
                  title: "Data Streams",
                  hideEdit: true,
                  parentIdField: "configId",
                  fields: (parents) => {
                    const serviceId = parents[0]?.serviceIdentifier;
                    
                    // Base fields that appear for all service types
                    const baseFields = [
                      { name: 'name', label: 'Name', type: 'text' },
                      { name: 'enabled', label: 'Enabled', type: 'checkbox' },
                      { name: 'schedule', label: 'Schedule', type: 'text' },
                      { 
                        name: 'kind', 
                        label: 'Kind', 
                        type: 'select',
                        options: [
                          { label: 'Node', value: 'n' },
                          { label: 'Location', value: 'l' }
                        ]
                      },
                      { name: 'objectId', label: 'Node/Location ID', type: 'number' },
                      { name: 'sourceId', label: 'Source ID', type: 'text' },
                    ];

                    // Service-specific fields
                    const serviceFields: Record<string, any[]> = {
                      's10k.c2c.ds.egauge': [
                        { name: 'deviceId', label: 'Device ID', type: 'text' },
                        { name: 'username', label: 'Username', type: 'text' },
                        { name: 'password', label: 'Password', type: 'password' },
                        { name: 'granularity', label: 'Granularity (seconds)', type: 'number' },
                      ],
                      's10k.c2c.i9n.locus': [
                        { 
                          name: 'granularity', 
                          label: 'Granularity', 
                          type: 'select',
                          options: [
                            { label: '1 Minute', value: '1min' },
                            { label: '5 Minutes', value: '5min' },
                            { label: '15 Minutes', value: '15min' },
                            { label: 'Hourly', value: 'hourly' },
                            { label: 'Daily', value: 'daily' },
                            { label: 'Monthly', value: 'monthly' },
                            { label: 'Yearly', value: 'yearly' },
                          ]
                        },
                        { name: 'placeholders', label: 'Placeholders', type: 'json' },
                      ],
                      's10k.c2c.i9n.solaredge.v1': [
                        {
                          name: 'resolution',
                          label: 'Resolution',
                          type: 'select',
                          options: [
                            { label: 'Quarter Hour', value: 'QUARTER_OF_AN_HOUR' },
                            { label: 'Hourly', value: 'HOURLY' },
                          ]
                        },
                        { name: 'placeholders', label: 'Placeholders', type: 'json' },
                        { name: 'sourceIdMap', label: 'Source ID Mapping', type: 'text' },
                      ],
                      's10k.c2c.ds.solrenview': [
                        {
                          name: 'granularity',
                          label: 'Granularity',
                          type: 'select',
                          options: [
                            { label: '1 Minute', value: '1min' },
                            { label: '5 Minutes', value: '5min' },
                            { label: '10 Minutes', value: '10min' },
                            { label: '15 Minutes', value: '15min' },
                            { label: '20 Minutes', value: '20min' },
                            { label: '30 Minutes', value: '30min' },
                            { label: 'Hourly', value: 'hourly' },
                            { label: 'Daily', value: 'daily' },
                            { label: 'Monthly', value: 'monthly' },
                            { label: 'Yearly', value: 'yearly' },
                          ]
                        },
                        { name: 'placeholders', label: 'Placeholders', type: 'json' },
                        { name: 'sourceIdMap', label: 'Source ID Mapping', type: 'text' },
                      ],
                    };

                    return [...baseFields, ...(serviceFields[serviceId] || [])];
                  },
                  onAdd: async (parentId, newStream, parents) => {
                    // Create service-specific properties based on the service identifier
                    const serviceSpecificProps: any = {};
                    
                    switch (newStream.serviceIdentifier) {
                      case 's10k.c2c.ds.egauge':
                        serviceSpecificProps.deviceId = newStream.deviceId;
                        serviceSpecificProps.username = newStream.username;
                        serviceSpecificProps.password = newStream.password;
                        serviceSpecificProps.granularity = newStream.granularity;
                        break;
                        
                      case 's10k.c2c.i9n.locus':
                        serviceSpecificProps.granularity = newStream.granularity;
                        serviceSpecificProps.placeholders = newStream.placeholders;
                        break;
                        
                      case 's10k.c2c.i9n.solaredge.v1':
                        serviceSpecificProps.resolution = newStream.resolution;
                        serviceSpecificProps.placeholders = newStream.placeholders;
                        serviceSpecificProps.sourceIdMap = newStream.sourceIdMap;
                        break;
                        
                      case 's10k.c2c.ds.solrenview':
                        serviceSpecificProps.granularity = newStream.granularity;
                        serviceSpecificProps.placeholders = newStream.placeholders;
                        serviceSpecificProps.sourceIdMap = newStream.sourceIdMap;
                        break;
                    }

                    await IntegrationsService.createIntegrationDataStream(selectedProfile, parentId.toString(), {
                      enabled: true,
                      name: newStream.name,
                      serviceIdentifier: parents[0].serviceIdentifier,
                      schedule: newStream.schedule,
                      kind: newStream.kind,
                      objectId: newStream.objectId,
                      sourceId: newStream.sourceId,
                      serviceProperties: serviceSpecificProps,
                      datumStreamMappingId: parseInt(parentId.toString()),
                    });
                  },
                  onUpdate: async (parentId, stream) => {
                    if (!stream.configId) return;
                    //console.log("onUpdate", parentId, stream);
                  },
                  onDelete: async (parentId, stream) => {
                    if (!stream.configId) return;

                    await IntegrationsService.deleteIntegrationDataStream(
                      selectedProfile,
                      stream.configId.toString()
                    );
                  },
                  getChildren: async (parentId) => {
                    return (await IntegrationsService.listIntegrationDataStreams(selectedProfile))
                    .filter(stream => stream.datumStreamMappingId === parentId);
                  }
                }
              }
            }
          }}
        />
      )}
    </TabPane>
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="inputs-theme" style={{ height: "86vh", overflowY: "auto" }}>
          <Nav tabs>
            <NavItem>
              <NavLink className={"general"} onClick={() => setTab("general")}>
                General
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={"Advanced"} onClick={() => setTab("Advanced")}>
                Advanced
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={"Data sharing"} onClick={() => setTab("Data sharing")}>
                Data sharing
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={"Energy API's"} onClick={() => setTab("Energy API's")}>
                Integrations
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={tabs} style={{marginInline: "20px"}}>
            <TabPane tabId={"general"}>
              <OrganizationFormFields
                control={control}
                errors={errors}
                organization={thisOrg}
                reset={reset}
                setValue={setValue}
              />
            </TabPane>
            <TabPane tabId={"Advanced"}>
              <OrganizationAdvancedFeilds
                control={control}
                errors={errors}
                organization={thisOrg}
                reset={reset}
                setValue={setValue}
              />
            </TabPane>
            <TabPane tabId={"Data sharing"}>
              <OrganizationDataSharingFeilds
                control={control}
                errors={errors}
                organization={thisOrg}
                reset={reset}
                setValue={setValue}
              />
              {watchEnableSharing !== "Do not share my data" ? renderDataSharingTable() : null}
            </TabPane>

            {renderIntegrationsTab()}

          </TabContent>
        </div>
        
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "8px", paddingRight: "12px" }}>
          <Button
            variation="primary"
            onClick={handleSubmit(onSubmit)}
            isLoading={submitting}
            loadingText="Updating organization..."
            isDisabled={!isDirty}
          >
            Update Organization
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}

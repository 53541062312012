import React from "react"
import { TabItem, Tabs, ThemeProvider, createTheme } from "@aws-amplify/ui-react"
import ExternalUsers from "./ExternalUsers"
import "@aws-amplify/ui-react/styles.css"
import ExternalUsersList from "./ExternalUsersList"

const theme = createTheme({
  name: "dark-theme",
  tokens: {
    colors: {
      background: {
        primary: { value: "black" },
      },
      font: {
        interactive: { value: "white" },
      },
    },
  },
})

const customStyles = `
  .custom-tabs [role="tab"] {
    color: white;
    border: none !important;
  }
  .custom-tabs [role="tab"]:hover {
    color: lightblue;
  }
  .custom-tabs [role="tab"]:focus, .custom-tabs [role="tab"][aria-selected="true"] {
    color: #03c2fc;
  }
`

const ExternalUsersTabRenderer = () => {
  return (
    <ThemeProvider theme={theme}>
      <style>{customStyles}</style>
      <Tabs className="custom-tabs">
        <TabItem title="Current External Users">
          <ExternalUsersList />
        </TabItem>
        <TabItem title="Invite A New User">
          <div style={{ color: "white", padding: "1rem" }}>
            <ExternalUsers />
          </div>
        </TabItem>
      </Tabs>
    </ThemeProvider>
  )
}

export default ExternalUsersTabRenderer
